import React from "react";
import Styled from "styled-components";
import ReactTooltip from 'react-tooltip';
import Clipboard from 'react-clipboard.js';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import { setValues } from "../store/redux";
import ImgList from "../constants/ImagesList";
import 'react-toastify/dist/ReactToastify.css';

class SectionFour extends React.Component{
  componentDidUpdate = () => {
    setTimeout(() => {
        ReactTooltip.rebuild();
    }, 200);
  }

  apiClaimItem = (key, type, image) => {
    fetch(
      process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_GETINFO,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.props.userData.jwtToken,
        },
        body: JSON.stringify({
          service: "claimItem",
          key,
          type
        }),
      }
    )
    .then((response) => response.json())
    .then((response) => {
      if (!response.status) {
        throw new Error(response.message);
      }
      this.props.setValues({
        loading: false,
        modal_open: "recieved",
        userInfo: response.data.userInfo,
        referral: response.data.referral,
        referral_item: response.data.items,
        items: response.data.items.name,
        image
      });
    })
    .catch((error) => {
      console.log("API Error : ", error);
      this.props.setValues({
        modal_open: "message",
        modal_message: error.message || "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
      });
    });
  }

  apiHistory = () => {
		fetch(
		  process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_GETINFO,
		  {
			method: "POST",
			headers: {
			  "Content-Type": "application/json",
			  Authorization: "Bearer " + this.props.userData.jwtToken,
			},
			body: JSON.stringify({
			  service: "getHistory"
			}),
		  }
		)
		.then((response) => response.json())
		.then((response) => {
		  if (!response.status) {
			throw new Error(response.message);
		  }
		  this.props.setValues({
			loading: false,
			modal_open: 'history',
			history_inviter: response.data.history_inviter,
			history_invitee: response.data.history_invitee,
			history_claim: response.data.history_claim
		  });
		})
		.catch((error) => {
		  console.log("API Error : ", error);
		  this.props.setValues({
			modal_open: "message",
			modal_message: error.message || "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
		  });
		});
  }

  handleClaimItem = (status, key, type, image) => {
    if (status !== 'clamed') {
      this.apiClaimItem(key, type, image);
    }
  }

  render() {
    const point_left = [
      { point: '1', position: '8.5%' },
      { point: '5', position: '18%' },
      { point: '10', position: '30%' },
      { point: '15', position: '43.5%' },
      { point: '20', position: '57.5%' },
      { point: '25', position: '72%' },
      { point: '30', position: '85%' },
    ];
    
    const point_right = [
      { point: '1', position: '10%' },
      { point: '5', position: '21%' },
      { point: '10', position: '32.5%' },
      { point: '15', position: '46%' },
      { point: '20', position: '59%' },
      { point: '25', position: '72.5%' },
      { point: '30', position: '86%' },
    ];

    return (
      <SectionStyle id="section4">
        <ReactTooltip
          id='itemreward'
          place='top'
          type='dark'
          effect='float'
          className='tooltip'
          html={true}
          overridePosition={({ left, top }, _e, _t, node) => {
            return {
                top, left: typeof node === 'string' ? left : Math.max(left, 0),
            };
          }}
        />
        { this.props.status === 'coming' ?
          <div>
            <img src={ ImgList.s4_comingsoon } alt="comingsoon" /> 
          </div>
        :
          <div className="s4">
            <div className="s4__top">
              <div className="title">
                <img src={ ImgList.s4_logo } alt="logo" />
              </div> 
              <div className="s4__milestone">
                <div className="reward reward--top">
                  <div className="reward__group" style={{ left: '0' }}>
                    <div className="reward__item" style={{ width: 'auto', bottom: '0' }}>คะแนน<br />ของคุณ</div>
                    {[1, 3, 5].map((item, index) => {
                      return (
                        <div 
                          className={ "reward__item " + (this.props.referral.inviter.items[item].status === 'enable' ? "active" : "disable") } 
                          key={ 'reward' + index } 
                          style={{ bottom: '0', left: point_left[item].position }}
                          onClick={ () => this.handleClaimItem(this.props.referral.inviter.items[item].status, this.props.referral.inviter.items[item].score, "inviter", this.props.referral.inviter.items[item].image) }
                        >
                          <img src={ this.props.referral.inviter.items[item].image } alt="reward" data-for='itemreward' data-tip={ this.props.referral.inviter.items[item].name } data-iscapture="true" />
                        </div>
                      )
                    })}
                  </div>
                  <div className="reward__group" style={{ right: '0', left: 'unset' }}>
                    {[6, 4, 2, 0].map((item, index) => {
                      return (
                        <div 
                          className={ "reward__item reward__item--right " + (this.props.referral.invitee.items[item].status === 'enable' ? "active" : "disable") } 
                          key={ 'reward' + index }  
                          style={{ bottom: '0', right: point_right[item].position }}
                          onClick={ () => this.handleClaimItem(this.props.referral.invitee.items[item].status, this.props.referral.invitee.items[item].score, "invitee", this.props.referral.invitee.items[item].image) }
                        >
                          <img src={ this.props.referral.invitee.items[item].image } alt="reward" data-for='itemreward' data-tip={ this.props.referral.invitee.items[item].name } data-iscapture="true" />
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="reward reward--bottom">
                  <div className="reward__group" style={{ left: '0' }}>
                    {[0, 2, 4, 6].map((item, index) => {
                      return (
                        <div 
                          className={ "reward__item " + (this.props.referral.inviter.items[item].status === 'enable' ? "active" : "disable") } 
                          key={ 'reward' + index } 
                          style={{ top: '0', bottom: 'unset', left: point_left[item].position }}
                          onClick={ () => this.handleClaimItem(this.props.referral.inviter.items[item].status, this.props.referral.inviter.items[item].score, "inviter", this.props.referral.inviter.items[item].image) }
                        >
                          <img src={ this.props.referral.inviter.items[item].image } alt="reward" data-for='itemreward' data-tip={ this.props.referral.inviter.items[item].name } data-iscapture="true" />
                        </div>
                      )
                    })}
                  </div>
                  <div className="reward__group" style={{ right: '0', left: 'unset' }}>
                    {[5, 3, 1].map((item, index) => {
                      return (
                        <div 
                          className={ "reward__item reward__item--right " + (this.props.referral.invitee.items[item].status === 'enable' ? "active" : "disable") } 
                          key={ 'reward' + index } 
                          style={{ top: '0', bottom: 'unset',right: point_right[item].position }}
                          onClick={ () => this.handleClaimItem(this.props.referral.invitee.items[item].status, this.props.referral.invitee.items[item].score, "invitee", this.props.referral.invitee.items[item].image) }
                        >
                          <img src={ this.props.referral.invitee.items[item].image } alt="reward" data-for='itemreward' data-tip={ this.props.referral.invitee.items[item].name } data-iscapture="true" />
                        </div>
                      )
                    })}
                    <div className="reward__item reward__item--invitee">คะแนน<br />ช่วยเหลือเพื่อน</div>
                  </div>
                </div>
                <GateStyle>
                  <div className="gate" style={{ left: '0' }}>
                    <div className="gate__bar" style={{ paddingLeft: '4%' }}>
                      <div className="gate__baractive" style={{ width: this.props.referral.inviter.percent + "%" }}></div>
                    </div>
                    <div className="gate__point">
                      { point_left.map((item, index) => {
                        return (
                          <div className={ "gate__point--left " + (this.props.referral.inviter.items[index].status === 'disable' ? "" : "gate__point--active") } key={ index } style={{ left: item.position }}>   
                            { item.point }
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="gate" style={{ right: '0' }}>
                    <div className="gate__bar" style={{ paddingRight: '4%' }}>
                      <div className="gate__baractive gate__right" style={{ width: this.props.referral.invitee.percent + "%" }}></div>
                    </div>
                    <div className="gate__point">
                      { point_right.map((item, index) => {
                        return (
                          <div className={ "gate__point--right " + (this.props.referral.invitee.items[index].status === 'disable' ? "" : "gate__point--active") } key={ index } style={{ right: item.position }}>   
                            { item.point }
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </GateStyle>
                <div className="banner"><img src={ ImgList.s4_banner } alt="banner" /></div>
              </div>
            </div>
            <div className="s4__bottom">
              <div className="bottom__score">
                <div>คะแนน <br /> ของคุณ</div>
                <div className="bottom__score--large">{ this.props.referral.inviter.point }</div>
                <div className="bottom__score--small">วันนี้เพื่อนสามารถช่วยเหลือคุณได้อีก <span>{ 3 - this.props.referral.inviter.today }/3 ครั้ง</span></div>
              </div>
              <div className="bottom__link">
                <div className="link__head">
                  <div className="btn__howto" onClick={ () => this.props.setValues({ modal_open: 'howto' }) }>วิธีการเล่น&nbsp;</div>
                  <div className="link__title"><img src={ ImgList.s4_title } alt="title" /></div>
                  <div className="btn__history" onClick={ () => this.apiHistory() }>ประวัติ&nbsp;</div>
                </div>
                <div className="link__input">
                  <input type="text" defaultValue={ this.props.userInfo.manager_name === '' ? '' : window.location.href.split("?key=")[0] + "?key=" + this.props.userInfo.keyid } />
                  <Clipboard 
                    className="btn__copy" 
                    data-clipboard-text={ this.props.userInfo.manager_name === '' ? '' : window.location.href.split("?key=")[0] + "?key=" + this.props.userInfo.keyid }
                    onClick={ () => toast("คัดลอกลิงก์สำเร็จ") }
                  >
                    <img src={ ImgList.s4_btn_copy } alt="btn_copylink" />
                  </Clipboard>
                </div>
                <div className="link__comment">** แชร์ลิงก์เพื่อให้เพื่อนช่วยเหลือ **</div>
              </div>
              <div className="bottom__score">
                <div>คะแนน <br /> ช่วยเหลือเพื่อน</div>
                <div className="bottom__score--large">{ this.props.referral.invitee.point }</div>
                <div className="bottom__score--small">วันนี้คุณสามารถช่วยเพื่อนได้อีก <span>{ 3 - this.props.referral.invitee.today }/3 ครั้ง</span></div>
              </div>
            </div>
            <ToastContainer
              className="toast"
              position="bottom-center"
              autoClose={ 3000 }
              closeButton={ false }
              hideProgressBar={ true }  
              draggable={ false }
              closeOnClick={ false }
              limit={ 1 }
              pauseOnFocusLoss
              pauseOnHover
            />
          </div>
        }
      </SectionStyle>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.Main, ...state.Modal , inGame: state.inGame });
const mapDispatchToProps = { setValues };
export default connect(mapStateToProps, mapDispatchToProps)(SectionFour);

const SectionStyle = Styled.div`
  width: 100vw;
  height: calc(100vw / 1920 * 1080);
  font-family: 'DBHelvethaicaX-73BdExt', Tahoma;
  .disable {
    filter:grayscale(0.8) brightness(0.8);
  }
  .toast {
    text-align: center;
    >div {
      font-family: 'DBHelvethaicaX-73BdExt', Tahoma;
      font-size: 1.5em;
      color: #ffffff;
      background: #444444d9;
    }
  }
  .s4 {
    width: 100%;
    height: 100%;
    background: url(${ImgList.s4_bg}) top center / cover no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    &__top {
      width: 100%;
      height: 80%;
      position: relative;
      .title {
        width: calc(100vw / 1920 * 726);
        position: absolute;
        top: 0;
        left: 11%;
        transform: translate(-11%, 0);
      }
    }
    &__bottom {
      width: 100%;
      height: 20%;
      padding: 3.5% 5% 0;
      text-align: center;
      background: url(${ImgList.s4_bottom}) bottom center / 100% 100% no-repeat;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 0;
      z-index: 2;
      @media screen and (max-device-width: 900px) {
        height: 25%;
        padding: 4.5% 5% 0;
      }
      .bottom {
        &__score {
          width: 25%;
          font-size: 0.7em;
          line-height: 1em;
          color: #fcfcf7;
          &--large {
            font-size: 7vw;
            line-height: 0.6em;
            color:#07f468;
          }
          &--small {
            >span {
              color: #07f468;
            }
          }
        }
        &__link {
          width: calc(100% - 54%);
          color: #fcfcf7;
        }
      }
    }
    &__milestone {
      width: 83%;
      height: 100%;
      margin: 0 auto;
      color: #161616;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }
  .link {
    width: 100%;
    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__title {
      width: 30%;
    }
    &__input {
      width: 100%;
      height: 100%;
      margin-top: 1%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      >input {
        width: 100%;
        height: calc(100vmin / 1920 * 80);
        padding-left: 2%;
        font-family: 'DBHelvethaicaX-73BdExt',Tahoma;
        font-size: 0.8em;
        background: url(${ImgList.s4_bg_copy}) top left / 100% 100% no-repeat;
        border: none;
        outline: none;
        pointer-events: none;
      }
      .btn__copy {
        width: auto;
        height: calc(100vmin / 1920 * 80);
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: -1%;
        transition: all .5s;
        outline: none;
        &:hover {
          transition: all .5s;
          filter: saturate(1.5);
        }
      }
    }
    &__comment {
      font-family: 'DBHelvethaicaX-63MedExt', Tahoma;
      font-size: 0.7em;
    }
  }
  .banner {
    width: 58%;
    margin: 0 auto;
    position: absolute;
    bottom: 5%;
    z-index: 2;
  }
  .btn {
    &__howto {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &::after {
        content: '';
        width: 0.8em;
        height: 0.8em;
        background: url(${ImgList.s4_ic_info}) bottom center / 100% 100% no-repeat;
      }
    }
    &__history {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &::after {
        content: '';
        width: 0.8em;
        height: 0.8em;
        background: url(${ImgList.s4_ic_history}) bottom center / 100% 100% no-repeat;
      }
    }
  }
  .reward {
    width: 100%;
    position: absolute;
    left: 0;
    transform: translate(0, -50%);
    z-index: 3;
    &--top {
      top: 43%;
    }
    &--bottom {
      bottom: 43%;
    }
    &__group {
      width: 42%;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      .active {
        width: 13%;
        cursor: pointer;
      }
    }
    &__item {
      width: 10%;
      text-align: center;
      position: absolute;
      bottom: 50%;
      transform: translate(-50%, 0);
      >img {
        transition: all .5s;
        &:hover {
          transform: scale(1.1);
          transition: all .5s;
        }
      }
      &--right {
        transform: translate(50%, 0);
      }
      &--invitee {
        width: auto;
        top: 0px;
        right: 0;
        bottom: unset;
        transform: translate(50%,0);
      }
    }
  }
`;

const GateStyle = Styled.div`
  width: 100%;
  padding-top: calc(100% / 1655 * 98);
  background: url(${ImgList.s4_bg_gate}) center center / 100% 100% no-repeat;
  display: block;
  position: relative;
  .gate {
    width: 42%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
    &__bar {
      width: 100%;
      &active {
        padding-top: calc(100% / 1920 * 65);
        background: url(${ ImgList.s2_milestone }) center center / cover no-repeat;
        border-radius: 30px;
      }
    }
    &__point {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      >div {
        -webkit-text-stroke: 1px #07f468;
        position: absolute;
        text-align: center;
        top: 50%;
        &.gate__point--left {
          transform: translate(-50%, -50%);
        }
        &.gate__point--right {
          transform: translate(50%, -50%);
        }
      }
      &--active {
        -webkit-text-stroke: unset !important;
        color: #161616;
      }
    }
    &__right {
      float: right;
      transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -webkit-transform: rotate(540deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
    }
  }
`;