// import cdn from './../static/images/';

const cdn                = process.env.REACT_APP_CDN_PATH;

const s1_bg_vdo          = cdn + 's1_bg.webm';
const s2_bg_vdo          = cdn + 's2_bg.webm';

export default {
    s1_bg_vdo,
    s2_bg_vdo
}