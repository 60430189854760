import React from 'react';
import { connect } from 'react-redux';

const InGameShow = props => {
  let { status } = props.inGame;
  let style_hide = {
    opacity: '0',
    userSelect: 'none',
    pointerEvents: 'none',
    width: 'min-content',
    height: 'min-content'
  };
  if (status) {
    return <React.Fragment>{props.children && props.children}</React.Fragment>;
  } else {
    return <div style={style_hide}>{props.children && props.children}</div>;
  }
};
const mapStateToProps = state => ({ inGame: state.inGame });
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InGameShow);
