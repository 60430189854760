import React from 'react';
import Styled from "styled-components";
import { connect } from "react-redux";
import {setValues , actCloseModal} from './redux';
import ModalCore from './ModalCore.js';

const LoadingModal = (props) => {
	let name = "loading";
	let {modal_open} = props;
	return (
		<ModalCore open={name === modal_open} actCloseModal={()=>console.log("test")}>
			<LoadingStyle>
				<div className="title">
					กรุณารอสักครู่...
				</div>
				<div className="loadingring" />
			</LoadingStyle>
		</ModalCore>
	)
}

const mapStateToProps = state => ({...state.Modal});
const mapDispatchToProps = { setValues ,actCloseModal};
export default connect( mapStateToProps, mapDispatchToProps)(LoadingModal);

const LoadingStyle = Styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
	.title {
		font-size: 1em;
		text-align: center;
		color:#fcfcf7;
	}
	.loadingring {
		margin: 1em;
		&:after {
			content: " ";
			display: block;
			width: 5vw;
			height: 5vw;
			border-radius: 50%;
			border: 0.5em solid;
			border-color: #07f468 transparent #07f468 transparent;
			/* border-color: #438208 transparent #a3e909 transparent; */
			animation: loadingringani 1.5s linear infinite;
		}
	}
	@keyframes loadingringani {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
