import React from 'react';
import Styled from 'styled-components';

const ModalCore = (props) => {
	return(
		<ModalCoreStyle open={ props.open }>
			<BackDrop />
			{ props.children && props.children }
		</ModalCoreStyle>
	)
}
export default ModalCore;

const ModalCoreStyle = Styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
    user-select: ${props=>props.open? "all":"none"};
    pointer-events: ${props=>props.open? "all":"none"};
	opacity: ${props=>props.open?1:0};
	transition: all 0.3s ease-in-out;
	position: fixed;
	top:0;
	left: 0;
	z-index: 8000;
`;

const BackDrop = Styled.div`
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.8);
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
`;
