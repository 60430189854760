import React from 'react';
// import React,{lazy} from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import OauthMiddleware from './middleware/OauthMiddleware';
import BlockDisplay from './features/blockdisplay';
import { InGameCheckCookie } from './features/InGame';
import configStore from './store/ConfigStore';
import Home from './pages/Home';
import InGame from './pages/InGame';
import Consent from './components/consent';
import './styles/index.scss';
// const Consent = lazy(() => import('./components/consent'));


const { store, persistor } = configStore();

const Routes = () => (
    <Provider store={ store }>
        <PersistGate loading={ null } persistor={ persistor }>
            <BrowserRouter>
                <>
                    <Route path={ process.env.REACT_APP_EVENT_PATH } component={ Consent } />
                    <Route path={ process.env.REACT_APP_EVENT_PATH } component={ OauthMiddleware } />
                    <Route path={ process.env.REACT_APP_EVENT_PATH } exact component={ BlockDisplay } />
                    <Route path={ process.env.REACT_APP_EVENT_PATH } exact component={ Home } />
                    <Route path={ '/' } component={ InGameCheckCookie } />
                    <Route path={ process.env.REACT_APP_EVENT_PATH + 'ingame' } exact component={ InGame } />
                </>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

export default Routes;
