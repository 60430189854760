import React from 'react';
import Styled from "styled-components";
import { connect } from "react-redux";
import { setValues } from './redux';
import ModalCore from './ModalCore.js';
import ImgList from "../../constants/ImagesList";

const MessageModal = (props) => {
	let name = "history";
	let {modal_open} = props;
	
	const [menu, setMenu] = React.useState(0); 
	
	return (
		<ModalCore open={ name === modal_open }>
			<ModalContent>
				<div className="title">
					<img src={ ImgList.title_history } alt="title_history" />
				</div>
				<div className="content">
					<CloseBtn onClick={ () => props.setValues({ modal_open: '' }) }>
						<img src={ ImgList.btn_close } alt="modal_close" />
					</CloseBtn>
					<div className="btn__group">
						<div className="btn__confirm" onClick={ () => setMenu(0) }>
							<img src={ menu === 0 ? ImgList.btn_friend_hover : ImgList.btn_friend } alt="btn_friend" />
						</div>
						<div className="btn__confirm" onClick={ () => setMenu(1) }>
							<img src={ menu === 1 ? ImgList.btn_you_hover : ImgList.btn_you } alt="btn_you" />
						</div>
						<div className="btn__confirm" onClick={ () => setMenu(2) }>
							<img src={ menu === 2 ? ImgList.btn_history_hover : ImgList.btn_history } alt="btn_history" />
						</div>
					</div>
					{ menu === 0 &&
						<div className="content__detail">
							<div className="content__title">
								<div>ชื่อผู้จัดการทีม</div>
								<div>คะแนน</div>
							</div>
							<div className="content__data">
								{ props.history_invitee.map((item, index) => {
									return (
										<div className="content__inner" key={ 'data_' + index }>
											<div>{ item.player }</div>
											<div>{ item.point }</div>
										</div>
									)
								})}
							</div>
						</div>
					}
					{ menu === 1 &&
						<div className="content__detail">
							<div className="content__title">
								<div>ชื่อผู้จัดการทีม</div>
								<div>คะแนน</div>
							</div>
							<div className="content__data">
								{ props.history_inviter.map((item, index) => {
									return (
										<div className="content__inner" key={ 'data_' + index }>
											<div>{ item.player }</div>
											<div>{ item.point }</div>
										</div>
									)
								})}
							</div>
						</div>
					}
					{ menu === 2 &&
						<div className="content__detail">
							<div className="content__title">
								<div>รายการ</div>
								<div>เวลา</div>
							</div>
							<div className="content__data">
								{ props.history_claim.map((item, index) => {
									return (
										<div className="content__inner" key={ 'data_' + index }>
											<div>{ item.name }</div>
											<div>{ item.time }</div>
										</div>
									)
								})}
							</div>
						</div>
					}
				</div>
			</ModalContent>
		</ModalCore>
	)
}

const mapStateToProps = state => ({ ...state.Main, ...state.Modal, item : state.Main.item });
const mapDispatchToProps = { setValues };
export default connect( mapStateToProps, mapDispatchToProps )(MessageModal);

const ModalContent = Styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.title {
		width: calc(100% / 211 * 51);
		margin-bottom: 2%;
	}
	.content {
		width: 100vmin;
    	height: calc(100vmin / 1578 * 1001);
		padding: 4% 4% 6%;
		font-size: 1em;
		color: #151616;
		background: url(${ ImgList.bg_large }) top center / 100% 100% no-repeat;
		word-break: break-word;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		@media screen and (max-device-width: 900px) {
			font-size: 1em;
		}
		&__detail {
			width: 100%;
			height: 90%;
			margin-top: 2%;
			padding: 2% 0 0;
			text-align: center; 
			line-height: 1em;
			background: url(${ ImgList.line }) top center / contain no-repeat;
			display: flex;
    		flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			color:#fcfcf7;
			>div {
				width: 100%;
    			text-align: center;
			}
		}
		&__title {
			margin-bottom: 2%;
			display: flex;
			justify-content: space-around;
			align-items: center;
			>div {
				width: 50%;
			}
		}
		&__data {
			height: 100%;
			color: #07f468;
			font-size: 0.8em;
			display: flex;
    		flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			overflow-y: scroll;
		}
		&__inner {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			>div {
				width: 50%;
				margin: 0 1%;
				text-align: center;
			}
		}
	}
	.btn {
		&__group {
			width: 100%;
			display: flex;
			justify-content: space-around;
			align-items: center;
		}
		&__confirm {
			width: calc(125vmin / 289 * 58);
			cursor: pointer;
		}
	}
`;

const CloseBtn = Styled.div`
    width: 6vmin;
    height: 6vmin;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(40%,0);
    cursor: pointer;
    z-index: 1000;
    transition: all .5s;
    :hover {
        transition: all .5s;
        filter: brightness(1.5);
    }
`;