import React from 'react';
import Styled from "styled-components";
import { connect } from "react-redux";
import { setValues } from './redux';
import ModalCore from './ModalCore.js';
import ImgList from "../../constants/ImagesList";

const MessageModal = (props) => {
	let name = "message";
	let { modal_open } = props;

	return (
		<ModalCore open={ name === modal_open }>
			<ModalContent>
				<CloseBtn onClick={ () => props.setValues({ modal_open: '' }) }>
					<img src={ ImgList.btn_close } alt="modal_close" />
				</CloseBtn>
				<div className="content__title">
					<img src={ ImgList.title_warning } alt="title_warning" />
				</div>
				<div className="content__detail">
					<div dangerouslySetInnerHTML={ {  __html: props.modal_message } } />
				</div>
				<div className="btn__group">
					<div className="btn__confirm" onClick={ () => props.setValues({ modal_open: "" }) }>
						<img src={ ImgList.btn_firm } alt="btn_firm" />
					</div>
				</div>
			</ModalContent>
		</ModalCore>
	)
}

const mapStateToProps = state => ({ ...state.Main, ...state.Modal });
const mapDispatchToProps = { setValues };
export default connect( mapStateToProps, mapDispatchToProps )(MessageModal);

const ModalContent = Styled.div`
	width: 100vmin;
    height: calc(100vmin / 806 * 469);
    padding: 5%;
	font-size: 1.2em;
	color: #151616;
    background: url(${ ImgList.bg_small }) top center / 100% 100% no-repeat;
    word-break: break-word;
    position: relative;
	display: flex;
	flex-direction: column;
    justify-content: center;
	align-items: center;
	.content {
		&__title {
			width: calc(100% / 211 * 51);
		}
		&__detail {
			margin: 5% 0;
			text-align: center; 
			font-size: 1.2em;
			color:#fcfcf7;
			p {
				font-size: 1.3em;
			}
		}
	}
	.btn {
		&__group {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&__confirm {
			width: calc(125vmin / 289 * 58);
			cursor: pointer;
		}
	}
`;

const CloseBtn = Styled.div`
    width: 6vmin;
    height: 6vmin;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(40%,-25%);
    cursor: pointer;
    z-index: 1000;
    transition: all .5s;
    :hover {
        transition: all .5s;
        filter: brightness(1.5);
    }
`;