import React from 'react';
import Styled from "styled-components";
import { connect } from "react-redux";
import { setValues } from './redux';
import ModalCore from './ModalCore.js';
import ImgList from "../../constants/ImagesList";

const MessageModal = (props) => {
	let name = "share";
	let {modal_open} = props;

	const handelRecieve = () => {
		window.open(props.milestones.items[props.items_key].link);
		fetch(
			process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_GETINFO,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + props.userData.jwtToken,
				},
				body: JSON.stringify({
					service: "claimMilestone",
					key: props.items_score
				}),
			}
		)
		.then((response) => response.json())
		.then((response) => {
			if (!response.status) {
			throw new Error(response.message);
			}
			props.setValues({
				loading: false,
				modal_open: "recieved",
				items: response.data.items,
				image: response.data.image,
				milestones: response.data.milestones
			});
		})
		.catch((error) => {
			console.log("API Error : ", error);
			props.setValues({
				modal_open: "message",
				modal_message: error.message || "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
			});
		});
	}

	return (
		<ModalCore open={ name === modal_open }>
			<ModalContent>
				<CloseBtn onClick={ () => props.setValues({ modal_open: '' }) }>
					<img src={ ImgList.btn_close } alt="modal_close" />
				</CloseBtn>
				<div className="content__title">
					<img className="" src={ ImgList.title_share } alt="title_share" />
				</div>
				<div className="content__detail">
					<img className="ic__facebook" src={ ImgList.ic_facebook } alt="ic_facebook" />
				</div>
				<div className="btn__group">
					<div className="btn__confirm" onClick={ () => handelRecieve() }>
						<img src={ ImgList.btn_share } alt="btn_share" />
					</div>
				</div>
			</ModalContent>
		</ModalCore>
	)
}

const mapStateToProps = state => ({ ...state.Modal, item : state.Main.item });
const mapDispatchToProps = { setValues };
export default connect( mapStateToProps, mapDispatchToProps )(MessageModal);

const ModalContent = Styled.div`
	width: calc(100% / 1920 * 1080);
    padding: 5%;
	font-size: 1.2em;
	color: #151616;
    background: url(${ ImgList.bg_small }) top center / 100% 100% no-repeat;
    word-break: break-word;
    position: relative;
	display: flex;
	flex-direction: column;
    justify-content: center;
	align-items: center;
	.content {
		&__title {
			width: 70%;
			>img {
				margin: auto;
			}
		}
		&__detail {
			margin: 5% 0;
			text-align: center; 
			color:#fcfcf7;
			.ic__facebook {
				width: 15%;
				margin: auto;
			}
		}
	}
	.btn {
		&__group {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&__confirm {
			width: calc(125vmin / 289 * 58);
			cursor: pointer;
		}
	}
`;

const CloseBtn = Styled.div`
    width: 6vmin;
    height: 6vmin;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(40%,0);
    cursor: pointer;
    z-index: 1000;
    transition: all .5s;
    :hover {
        transition: all .5s;
        filter: brightness(1.5);
    }
`;