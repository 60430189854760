import { useEffect } from 'react';
import { connect } from 'react-redux';
import { setInGame } from './redux';

const CheckCookie = props => {
  // console.log(props);
  let { cookie_name, status } = props.inGame;
  let cookie = getCookie(cookie_name);
  let in_game_status = cookie !== '' ? true : false;
  useEffect(() => {
    if (!status && in_game_status) {
      let newInGameData = { ...props.inGame, status: true };
      props.setInGame(newInGameData);
    }
  }, []);
  return false;
};
//mapStateToProps
const mstp = state => ({ inGame: state.inGame });
// mapDispatchToProps
const mdtp = { setInGame };
export default connect(
  mstp,
  mdtp
)(CheckCookie);

// function ====================================================================
const getCookie = cname => {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
//==============================================================================
