import consent_values from './../components/consent/src/reduxState';

const initialState = {

  ...consent_values,

  jwtToken: '',
  error: false,
  loading: false,
  hideLoader: false,
  loginUrl: '',
  logoutUrl: '',
  doLogout: false,
  userData: {
    access_token: "",
    exp: 0,
    gender: 0,
    icon: '',
    jwtToken: '',
    nickname: '',
    nonce: 0,
    open_id: '',
    platform: 0,
    uid: 0
  },

  register: {
    status: false,
    link_share: ''
  },
  userInfo: {
      manager_name: '',
      inviter: 0,
      invitee: 0,
      keyid: '',
      lt: 0
  },
  milestones: {
    user_registed: '',
    percent: '',
    items: [
      {
        score: 100000,
        image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/section2/reward_1.png',
        name: '[MC] Multi Class (OVR 92+) <span>X1</span>',
        link: '',
        status: 'disable'
      },
      {
        score: 300000,
        image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/section2/reward_2.png',
        name: 'คูปองลดภาษี ทุกคลาส 10% <span>X1</span>',
        link: '',
        status: 'disable'
      },
      {
        score: 500000,
        image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/section2/reward_3.png',
        name: 'MC, VTR, BoE, LH OVR 85+ (1~5) <span>X1</span>',
        link: '',
        status: 'disable'
      },
      {
        score: 700000,
        image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/section2/reward_4.png',
        name: 'Lucky BP Card (50M - 1000M BP) <span>X1</span>',
        link: '',
        status: 'disable'
      },
      {
        score: 1000000,
        image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/section2/reward_5.png',
        name: 'MC, VTR, BoE, LH OVR 85+ (3~5) <span>X1</span> และ SVIP (3 Days) <span>X1</span>',
        link: '',
        status: 'disable'
      }
    ]
  },
  countdown: {
    status: 'coming',
    time: ''
  },
  golden_goal: '',
  referral: {
    inviter: {
      point: 0,
      percent: 0,
      items: [
        {
          score: 1,
          image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/rewards-inviter/1.png',
          name: 'SVIP (3 Days) <span>X1</span>',
          status: 'disable'
        },
        {
          score: 5,
          image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/rewards-inviter/2.png',
          name: '[MC] Multi Class (OVR 90+) <span>X1</span>',
          status: 'disable'
        },
        {
          score: 10,
          image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/rewards-inviter/3.png',
          name: 'Lucky BP Card (50M - 500M BP) <span>X1</span>',
          status: 'disable'
        },
        {
          score: 15,
          image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/rewards-inviter/4.png',
          name: 'MC,VTR,20NG (OVR 90+) <span>X1</span>',
          status: 'disable'
        },
        {
          score: 20,
          image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/rewards-inviter/5.png',
          name: 'MC, VTR (OVR 90+) <span>X1</span>',
          status: 'disable'
        },
        {
          score: 25,
          image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/rewards-inviter/6.png',
          name: 'MC, ICONS (OVR 90+) <span>X1</span>',
          status: 'disable'
        },
        {
          score: 30,
          image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/rewards-inviter/7.png',
          name: 'MC, VTR, BoE, LH OVR 90+ (3~5) <span>X1</span>',
          status: 'disable'
        }
      ],
      today: 0
    },
    invitee: {
      point: 0,
      percent: 0,
      items: [
        {
            score: 1,
            image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/rewards-invitee/1.png',
            name: 'คูปองลดภาษี ทุกคลาส 20% <span>X1</span>',
            status: 'disable'
        },
        {
            score: 5,
            image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/rewards-invitee/2.png',
            name: 'MC, VTR, BoE, LH OVR 85+ (1~5) <span>X1</span>',
            status: 'disable'
        },
        {
            score: 10,
            image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/rewards-invitee/3.png',
            name: '100,000,000 BP <span>X1</span>',
            status: 'disable'
        },
        {
            score: 15,
            image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/rewards-invitee/4.png',
            name: 'MC, VTR, BoE, LH OVR 90+ (2~4) <span>X1</span>',
            status: 'disable'
        },
        {
            score: 20,
            image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/rewards-invitee/5.png',
            name: 'MC, 20NG (OVR 90+) <span>X1</span>',
            status: 'disable'
        },
        {
            score: 25,
            image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/rewards-invitee/6.png',
            name: '20NG (OVR 94+) <span>X1</span>',
            status: 'disable'
        },
        {
            score: 30,
            image: 'https://cdngarenanow-a.akamaihd.net/webth/cdn/fo4/davidbeckham/rewards-invitee/7.png',
            name: 'MC (OVR 94+) <span>X1</span>',
            status: 'disable'
        }
      ],
      today: 0
    }
  },
  referral_item: {},
  history_inviter: [
    {
      player: '',
      time: '',
      point: 0
    }
  ],
  history_invitee: [
    {
      player: '',
      time: '',
      point: 0
    }
  ],
  history_claim: [
    {
        name: '',
        time: ''
    }
  ],

  items: '',
  image: '',
  
  items_key: '',
  items_score: '',
  introheight: ''
};

const SET_LOGIN_URL = 'SET_LOGIN_URL';
const SET_LOGOUT_URL = 'SET_LOGOUT_URL';

export const MainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_URL      : return { ...state, loginUrl: action.value };
    case SET_LOGOUT_URL     : return { ...state, logoutUrl: action.value };
    case "SET_VALUES"       : return { ...state, ...action.value };
    default:
      return state;
  }
};

export const setLoginUrl    = value => ({ type: SET_LOGIN_URL, value});
export const setLogoutUrl   = value => ({ type: SET_LOGOUT_URL, value});
export const setValues      = value => ({ type: 'SET_VALUES', value });
// export const actCloseModal  = value => ({type:"CLOSE",value});