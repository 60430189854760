import React from 'react';
import Styled from "styled-components";
import { connect } from "react-redux";
import { setValues } from './redux';
import ModalCore from './ModalCore.js';
import ImgList from "../../constants/ImagesList";

const MessageModal = (props) => {
	let name = "howto";
	let {modal_open} = props;

	return (
		<ModalCore open={ name === modal_open }>
			<ModalContent>
				<CloseBtn onClick={ () => props.setValues({ modal_open: '' }) }>
					<img src={ ImgList.btn_close } alt="modal_close" />
				</CloseBtn>
				<img src={ ImgList.howto } alt="howto" />
			</ModalContent>
		</ModalCore>
	)
}

const mapStateToProps = state => ({ ...state.Modal, item : state.Main.item });
const mapDispatchToProps = { setValues };
export default connect( mapStateToProps, mapDispatchToProps )(MessageModal);

const ModalContent = Styled.div`
	width: 100vw;
	height: 100vh;
    word-break: break-word;
    position: relative;
	display: flex;
	flex-direction: column;
    justify-content: center;
	align-items: center;
	.content {
		&__title {
			width: 70%;
			>img {
				margin: auto;
			}
		}
		&__detail {
			margin: 5% 0;
			text-align: center; 
			color:#fcfcf7;
			.ic__facebook {
				width: 15%;
				margin: auto;
			}
		}
	}
	.btn {
		&__group {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&__confirm {
			width: calc(125vmin / 289 * 58);
			cursor: pointer;
		}
	}
`;

const CloseBtn = Styled.div`
    width: 6vmin;
    height: 6vmin;
    position: absolute;
    top: 3%;
    right: 2%;
    cursor: pointer;
    z-index: 1000;
    transition: all .5s;
    :hover {
        transition: all .5s;
        filter: brightness(1.5);
    }
`;