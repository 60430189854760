import React from "react";
// import Countdown, { zeroPad } from 'react-countdown';
import Styled from "styled-components";
import { connect } from "react-redux";
import { setValues } from "../store/redux";
import VdoList from "../constants/VideoList";
import ImgList from "../constants/ImagesList";
class SectionOne extends React.Component {
  render() {
    return (
      <SectionStyle id="section1">
        <div className="background__video">
          <video loop muted autoPlay>
            <source src={ VdoList.s1_bg_vdo } type="video/webm" />
          </video>
        </div>
        <div className="s1__logo"><img src={ ImgList.logo } alt="logo" /></div>
        <div className="s1__content">
          <div className="s1__video">
            <div className="s1__video--wrapper">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLLA8pYYUiRlHrVUTCJ-f4RQPIQn9iwTsm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
            </div>
          </div>
          <div className="s1__banner">
            {/* { this.props.countdown.status === "coming" && */}
              <img src={ ImgList.s1_banner_1 } alt="banner_1" />
            {/* } */}
            {/* { this.props.countdown.status === "countdown" &&
              <div>
                <img src={ ImgList.s1_banner_2 } alt="banner_2" />
                <Countdown
                  date={ new Date(this.props.countdown.time.replace(/-/g, '/')) }
                  daysInHours={ true }
                  renderer={ (props) => 
                    <div className="countdown" key={ props.seconds }>
                      <div className="countdown__time">
                        <div>{ zeroPad((props.days * 24) + props.hours) }</div>
                        <span>HOURS</span>
                      </div>
                      <div>:</div>
                      <div className="countdown__time">
                        <div>{ zeroPad(props.minutes) }</div>
                        <span>MINUTES</span>
                      </div>
                      <div>:</div>
                      <div className="countdown__time">
                        <div>{ zeroPad(props.seconds) }</div>
                        <span>SECONDS</span>
                      </div>
                    </div>
                  }
                />
              </div>
            } */}
            {/* { this.props.countdown.status === "open" &&
              <img src={ ImgList.s1_banner_3 } alt="banner_3" />
            } */}
          </div>
        </div>
      </SectionStyle>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.Main, ...state.Modal , inGame: state.inGame });
const mapDispatchToProps = { setValues };
export default connect(mapStateToProps, mapDispatchToProps)(SectionOne);

const SectionStyle = Styled.div`
  width: 100vw;
  height: calc(100vw / 1920 * 1080);
  margin-top: -4%;
  background: url(${ ImgList.s1_bg }) top right / contain no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .s1 {
    &__logo {
      width: calc(100vw / 1920 * 75);
      position: absolute;
      top: 15%;
      right: 2%;
      transform: translate(-5%, -15%);
    }
    &__content {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      left: 5%;
    }
    &__video {
      width: 100%;
      padding: 2.5%;
      background: url(${ ImgList.s1_bg_video }) top center / 100% 100% no-repeat;
      position: relative;
      &--wrapper {
        height: 0;
        padding-top: 25px;
        padding-bottom: 56.25%;
        position: relative;
        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    &__banner {
      width: 70%;
      margin: 5% auto 0;
      color: #161616;
    }
  }
  .countdown {
    margin-top: 5%;
    font-family: 'DBHelvethaicaX-73BdExt', Tahoma;
    font-size: 4em;
    line-height: 0.2em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: baseline;
    >div {
      margin: 0 2%;
    }
    &__time {
      span {
        font-family: 'DBHelvethaicaX-63MedExt', Tahoma;
        font-size: 0.2em;
      }
    }
  }
`;