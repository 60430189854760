// import cdn                  from   './../static/images';

const cdn                   = process.env.REACT_APP_CDN_PATH;

const intro                 = cdn + 'intro.png';

// Section 1
const logo                  = cdn + 'section1/logo.png';
const s1_bg                 = cdn + 'section1/bg.png';
const s1_bg_video           = cdn + 'section1/bg_video.png';
const s1_banner_1           = cdn + 'section1/banner_1.png';
const s1_banner_2           = cdn + 'section1/banner_2.png';
const s1_banner_3           = cdn + 'section1/banner_3.png';
const s1_btn_login          = cdn + 'section1/btn_login.png';
const s1_btn_logout         = cdn + 'section1/btn_logout.png';

// Section 2
const s2_bg                 = cdn + 'section2/bg_2.png';
const s2_bg_title           = cdn + 'section2/bg_title.png';
const s2_bg_total_view      = cdn + 'section2/bg_total_view.png';
const s2_bg_recieve         = cdn + 'section2/bg_recieve.png';
const s2_btn_register_bg    = cdn + 'section2/btn_register_bg.gif';
const s2_btn_register       = cdn + 'section2/btn_register.png';
const s2_btn_register_hover = cdn + 'section2/btn_register_hover.png';
const s2_btn_share          = cdn + 'section2/btn_share.png';
const s2_btn_share_hover    = cdn + 'section2/btn_share_hover.png';
const s2_banner             = cdn + 'section2/banner.png';
const s2_milestone          = cdn + 'section2/milestone.png';
const s2_reward_1           = cdn + 'section2/reward_1.png';
const s2_reward_2           = cdn + 'section2/reward_2.png';
const s2_reward_3           = cdn + 'section2/reward_3.png';
const s2_reward_4           = cdn + 'section2/reward_4.png';
const s2_reward_5           = cdn + 'section2/reward_5.png';

// Section 3
const s3_bg                 = cdn + 'section3/bg.png';
const s3_banner_1           = cdn + 'section3/banner_1.png';
const s3_banner_2           = cdn + 'section3/banner_2.png';
const s3_banner_3           = cdn + 'section3/banner_3.png';
const s3_banner_4           = cdn + 'section3/banner_4.png';
const s3_title              = cdn + 'section3/title.png';

// Section 4
const s4_comingsoon         = cdn + 'section4/comingsoon.png';
const s4_bg                 = cdn + 'section4/s4_bg_1.png';
const s4_logo               = cdn + 'section4/logo.png';
const s4_banner             = cdn + 'section4/banner_david_3.png';
const s4_bg_gate            = cdn + 'section4/bg_gate.png';
const s4_bottom             = cdn + 'section4/s4_bottom.png';
const s4_bg_copy            = cdn + 'section4/bg_copy.png';
const s4_btn_copy           = cdn + 'section4/btn_copy.png';
const s4_title              = cdn + 'section4/title.png';
const s4_ic_history         = cdn + 'section4/ic_history.png';
const s4_ic_info            = cdn + 'section4/ic_info.png';

// Footer
const logo_ea               = cdn + 'footer/logo_ea.png';
const logo_fifa             = "https://cdn-webth.garenanow.com/webth/cdn/fo4/logo_fifa_white.png";
const logo_garena           = cdn + 'footer/logo_garena.png';

// Popup 
const bg_large              = cdn + 'popup/bg_large.png';
const bg_medium             = cdn + 'popup/bg_medium.png';
const bg_medium_2           = cdn + 'popup/bg_medium_2.png';
const bg_small              = cdn + 'popup/bg_small.png';
const bg_small_2            = cdn + 'popup/bg_small_2.png';
const btn_close             = cdn + 'popup/btn_close.png';
const btn_share             = cdn + 'popup/btn_share.png';
const btn_firm              = cdn + 'popup/btn_firm.png';
const btn_cancel            = cdn + 'popup/btn_cancel.png';
const btn_you               = cdn + 'popup/btn_you.png';
const btn_you_hover         = cdn + 'popup/btn_you_hover.png';
const btn_friend            = cdn + 'popup/btn_friend.png';
const btn_friend_hover      = cdn + 'popup/btn_friend_hover.png';
const btn_history           = cdn + 'popup/btn_history.png';
const btn_history_hover     = cdn + 'popup/btn_history_hover.png';
const line                  = cdn + 'popup/line.png';
const title_congrats        = cdn + 'popup/title_congrats.png';
const title_congrats_2      = cdn + 'popup/title_congrats_2.png';
const title_share           = cdn + 'popup/title_share.png';
const title_warning         = cdn + 'popup/title_warning.png';
const title_history         = cdn + 'popup/title_history.png';
const ic_facebook           = cdn + 'popup/ic_facebook.png';
const howto                 = cdn + 'popup/howto-1.png';

export default {
    intro,

    // Section 1
    logo,
    s1_bg,
    s1_bg_video,
    s1_banner_1,
    s1_banner_2,
    s1_banner_3,
    s1_btn_login,
    s1_btn_logout,

    // Section 2
    s2_bg,
    s2_bg_title,
    s2_bg_total_view,
    s2_bg_recieve,
    s2_btn_register_bg,
    s2_btn_register,
    s2_btn_register_hover,
    s2_btn_share,
    s2_btn_share_hover,
    s2_banner,
    s2_milestone,
    s2_reward_1,
    s2_reward_2,
    s2_reward_3,
    s2_reward_4,
    s2_reward_5,

    // Section 3
    s3_bg,
    s3_banner_1,
    s3_banner_2,
    s3_banner_3,
    s3_banner_4,
    s3_title,

    // Section 4
    s4_comingsoon,
    s4_bg,
    s4_logo,
    s4_banner,
    s4_bg_gate,
    s4_bottom,
    s4_bg_copy,
    s4_btn_copy,
    s4_title,
    s4_ic_history,
    s4_ic_info,

    // Footer
    logo_ea,
    logo_fifa,
    logo_garena,

    // Popup
    bg_large,
    bg_medium,
    bg_medium_2,
    bg_small,
    bg_small_2,
    btn_close,
    btn_share,
    btn_firm,
    btn_cancel,
    btn_you,
    btn_you_hover,
    btn_friend,
    btn_friend_hover,
    btn_history,
    btn_history_hover,
    line,
    title_congrats,
    title_congrats_2,
    title_share,
    title_warning,
    title_history,
    ic_facebook,
    howto
}