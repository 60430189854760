import Menu from './_Menu';
import Hide from './_Hide';
import Show from './_Show';
import SetIn from './_SetIn';
import SetOut from './_SetOut';
import CheckCookie from './_CheckCookie';

export const InGameMenu = Menu;
export const InGameHide = Hide;
export const InGameShow = Show;
export const InGameSetIn = SetIn;
export const InGameSetOut = SetOut;
export const InGameCheckCookie = CheckCookie;

// module.exports = require('./Hide')
// module.exports = const module = require('module');;
