import React from "react";
import Styled from "styled-components";
import { connect } from "react-redux";
import { setValues } from "../store/redux";
import { checkJwtCookie, setCookie, getCookie } from "../constants/Cookie";
import ImgList from "../constants/ImagesList";
import SectionOne from "../features/SectionOne";
import SectionTwo from "../features/SectionTwo";
import SectionThree from "../features/SectionThree";
import SectionFour from "../features/SectionFour";
import { InGameMenu } from "../features/InGame";
import {  MessageModal, 
          LoadingModal, 
          RegisteredModal, 
          IngameRegisteredModal, 
          IngameSharedModal,
          ShareModal, 
          RecievedModal,
          RecievedPointModal,
          HowtoModal,
          HistoryModal } from "../features/modal";

class Home extends React.Component {
  componentDidMount = () => {
    let token_url = window.location.href.split("?token=")[1] || '';
    let key_url = window.location.href.split("?key=")[1] || '';
    let key_user = getCookie("key_user") || '';
    if (token_url === '' && key_url === '' && key_user === '') {
      this.apiGetHome();
    }
    setTimeout( () => { 
      // var getintro = document.getElementById("intro");
      // var height_intro = getintro.offsetHeight;
      // window.scrollTo(0, height_intro); 
      this.props.setValues({
        introheight: '0',
      })
    }, 3000);

  };

  componentDidUpdate = (prevProps) => {
    let token_url = window.location.href.split("?token=")[1] || '';
    let key_url = window.location.href.split("?key=")[1] || '';
    let key_user = getCookie("key_user") || '';
    let jwt_token = getCookie("jwt_token") || '';

    if (key_url !== '') {
      setCookie("key_user", key_url , 24, "");
      window.location= window.location.href.split("?key=")[0];
      return;
    }

    if (jwt_token === '' && key_user !== '' && token_url === '') {
      window.location= this.props.loginUrl;
      return;
    }

    if (this.props.userData.jwtToken !== '' && this.props.userData.jwtToken !== prevProps.userData.jwtToken) {
      this.apiGetInfo();
      return;
    }

    if (key_user !== '' && this.props.userInfo.manager_name !== '') {
      setCookie("key_user", '' , 24, "");
      this.apiInviteLink(this.props.userData.jwtToken, key_user);
    }
  };

  apiGetHome = () => {
    fetch(
      process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_MAIN,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      }
    )
    .then((response) => response.json())
    .then((response) => {
      if (!response.status) {
        throw new Error(response.message);
      }
      this.props.setValues({
        loading: false,
        modal_open: "",
        register: response.data.register,
        milestones: response.data.milestones,
        countdown: response.data.countdown,
        golden_goal: response.data.golden_goal,
        referral: response.data.referral
      });
    })
    .catch((error) => {
      console.log("API Error : ", error);
      this.props.setValues({
        modal_open: "message",
        modal_message: error.message || "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
      });
    });
  };

  apiGetInfo = () => {
    if(!checkJwtCookie()){
      this.props.setValues({
          modal_open: 'message',
          modal_message: 'กรุณาเข้าสู่ระบบ'
      })
      return;
    }

    if (this.props.loading) return;
    this.props.setValues({
        loading: true,
        modal_open: 'loading'
    })

    fetch(
      process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_GETINFO,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.props.userData.jwtToken,
        },
        body: JSON.stringify({
          service: "getInfo",
        }),
      }
    )
    .then((response) => response.json())
    .then((response) => {
      if (!response.status) {
        throw new Error(response.message);
      }
      this.props.setValues({
        loading: false,
        modal_open: "",
        consent_key: response.data.consent_key,
        userInfo: response.data.userInfo,
        register: response.data.register,
        milestones: response.data.milestones,
        countdown: response.data.countdown,
        golden_goal: response.data.golden_goal,
        referral: response.data.referral
      });
    })
    .catch((error) => {
      console.log("API Error : ", error);
      this.props.setValues({
        modal_open: "message",
        modal_message: error.message || "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
      });
    });
  };

  apiInviteLink = (jwtToken, keyid) => {
    fetch(
      process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_GETINFO,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwtToken,
        },
        body: JSON.stringify({
          service: "inviteLink",
          keyid
        }),
      }
    )
    .then((response) => response.json())
    .then((response) => {
      if (!response.status) {
        throw new Error(response.message);
      }
      this.props.setValues({
        loading: false,
        modal_open: "recieved_point",
        modal_message: response.message,
        userInfo: response.data.userInfo,
        referral: response.data.referral
      });
    })
    .catch((error) => {
      console.log("API Error : ", error);
      this.props.setValues({
        modal_open: "message",
        modal_message: error.message || "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
      });
    });
  }

  render() {
    return (
      <HomeStyle>
        <InGameMenu landingtoken={ this.props.userInfo.lt }/>
        <Intro style={{ height: this.props.introheight }}>
          <img src={ ImgList.intro } alt="banner_intro" />
        </Intro>
        
        <Navbar className="nav">
          <ul className="nav__menu">
            <li><a href="#section1">หน้าแรก</a></li>
            <li><a href="#section2">ลงทะเบียน</a></li>
            <li><a href="#section3">ประวัติ</a></li>
            <li style={{ opacity: this.props.golden_goal === 'close' ? '0' : '1' }}><a href="#section4">กิจกรรมรับของฟรี</a></li>
          </ul>
          { this.props.userData.jwtToken !== '' && this.props.userInfo.manager_name !== '' ?
            <ul className="nav__user">
              <li><div>สวัสดีคุณ</div><p className="nav__username">{ this.props.userInfo ? this.props.userInfo.manager_name: "no-name" }</p></li>
              { !this.props.inGame.status &&
                <li className="nav__btn">
                  <img src={ ImgList.s1_btn_logout } alt="btn_logout" onClick={ ()=> this.props.setValues({ doLogout: true }) } />
                </li>
              }
            </ul>
          :
            <ul className="nav__user">
              <li className="nav__btn">
                <a href={ this.props.loginUrl }>
                  <img src={ ImgList.s1_btn_login } alt="btn_login" />
                </a>
              </li>
            </ul>
          }
        </Navbar>

        <SectionOne />
        <SectionTwo />
        <SectionThree />
        { this.props.golden_goal === 'close' ?
          null
        :
          <SectionFour status={ this.props.golden_goal } />
        }
        <Footer className="footer">
          <div className="footer__main">
            <div className="footer__logo">
              <img src={ ImgList.logo_garena } className="footer__logo--garena" alt="logo_garena" />
              <img src={ ImgList.logo_ea } className="footer__logo--ea" alt="logo_ea" />
              <img src={ ImgList.logo_fifa } className="footer__logo--fifa" alt="logo_fifa" />
            </div>
            <div className="footer__copyright">
              ©2020 Electronic Arts Inc. EA, EA SPORTS, and the EA SPORTS logo are trademarks of Electronic Arts Inc. Official FIFA licensed product. © FIFA and FIFA's Official Licensed Product Logo are copyrights and/or trademarks of FIFA. All rights reserved. Manufactured under license by Electronic Arts Inc.
            </div>
          </div>
        </Footer>

        {/* Modals */}
        <LoadingModal />
        <MessageModal />
        <RegisteredModal />
        <IngameSharedModal />
        <IngameRegisteredModal />
        <ShareModal />
        <RecievedModal />
        <RecievedPointModal />
        <HowtoModal />
        <HistoryModal />
      </HomeStyle>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.Main, ...state.Modal , inGame: state.inGame });
const mapDispatchToProps = { setValues };
export default connect(mapStateToProps, mapDispatchToProps)(Home);

const Intro =Styled.div`
  width: 100vw;
  height: 100vh;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: height .3s ease-in-out;
  z-index: 10;
  >img {
    max-height: unset;
    animation: fadeIn ease 1s;
    @keyframes fadeIn {
      0% { 
        width: 80%;
        opacity:0; 
      }
      100% { 
        width: 100%;
        opacity:1; 
      }
    }
  }
`;

const HomeStyle = Styled.div`
  width: 100%;
  position: relative;
  display: block;
  .background {
    &__video {
      width: fit-content;
      height: fit-content;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      >video {
        min-width: 100vw;
        min-height: 100vh;
        width: calc(100vh / 1080 * 1920);
        height: calc(100vw / 1920 * 1080);
        position: relative;
        display: block;
        @media only screen and (max-width: 1024px) {
          display: none;
        }
        @media only screen and (max-device-width: 1366px) {
          display: none;
        }
      }
    }
  }
  .text__yellow {
    color: #07f468;
  }
  .text__black {
    color: #07f468;
  }
`;

const Navbar = Styled.div`
  width: 100%;
  padding: 1%;
  color: #151616;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #161616;
  position: sticky;
  top: 0;
  z-index: 5;
  border-bottom: 2px solid #07f468;
  >ul {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav {
    &__menu {
      width: 70%;
      >li {
        margin: 0 5%;
        transition: all .5s;
        cursor: pointer;
        color: #07f468;
        &:hover {
          // color: #07f468;
          text-decoration: underline;
          transition: all .5s;
        }
        span {
          font-family: 'Flama-BlackItalic', Tahoma;
        }
      }
    }
    &__user {
      width: 30%;
      justify-content: flex-end;
      >li {
        &:first-child {
          display: flex;
          justify-content: center;
          align-items: baseline;
        }
        >div {
          font-size: 0.8em;
          color: #07f468;
        }
      }
    }
    &__username {
      max-width: 10vw;
      margin: 0 10px;
      font-family: 'DBHelvethaicaX-73BdExt', Tahoma;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: #fcfcf7;
    }
    &__btn {
      width: calc(100% / 116 * 31);
      transition: all .5s;
      &:hover {
        transition: all .5s;
        transform: scale(1.1);
      }
    }
  }
`;

const Footer = Styled.div`
  font-family: "PSLKittithadaPro", Tahoma;
  color:#fff;
  text-transform: uppercase;
  background: #0b0b0b;
  .footer {
    &__main {
      width: 65%;
      margin: 0 auto;
      padding: 20px 0;
      text-align: center;
      display: block;
      @media(max-width: 768px) {
          width: 90%;
      }
    }
    &__logo {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media(max-width: 768px) {
        justify-content: space-between;
      }
      >img {
        margin: 0 2%;
      }
      &--garena {
        width: 170px;
        @media(max-width: 768px) {
          width: 30%;
        }
      }
      &--ea {
        width: 55px;
        @media(max-width: 768px) {
          width: 13%;
        }
      }
      &--fifa {
        width: 220px;
        @media(max-width: 768px) {
          width: 40%;
        }
      }
    }
    &__copyright {
      font-size: 13px;
      line-height: 13px;
    }
  }
`;