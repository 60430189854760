import React from "react";
import Styled from "styled-components";
import ReactTooltip from 'react-tooltip';
import { connect } from "react-redux";
import { setValues } from "../store/redux";
// import { setCookie, getCookie } from "../constants/Cookie";
import VdoList from "../constants/VideoList";
import ImgList from "../constants/ImagesList";

class SectionTwo extends React.Component {
  componentDidUpdate = () => {
    setTimeout(() => {
        ReactTooltip.rebuild();
    }, 200);
  
    //login auth
    // let register_auto = getCookie("register_auto");
    // if (this.props.userInfo.manager_name === '' && this.props.userData.jwtToken !== '' && register_auto) {
    //   this.apiRegister();
    //   setCookie("register_auto", '' , 24, "");
    // }
  }

  apiRegister = () => {
    fetch(
      process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_GETINFO,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.props.userData.jwtToken,
        },
        body: JSON.stringify({
          service: "register",
        }),
      }
    )
    .then((response) => response.json())
    .then((response) => {
      if (!response.status) {
        throw new Error(response.message);
      }
      this.props.setValues({
        loading: false,
        modal_open: "registered",
        userInfo: response.data.userInfo,
        register: response.data.register,
        milestones: response.data.milestones,
      });
    })
    .catch((error) => {
      console.log("API Error : ", error);
      this.props.setValues({
        modal_open: "message",
        modal_message: error.message || "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
      });
    });
  }
  
  numberWithCommas(total_view) {
    return total_view.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  handleRegister = () => {
    if (this.props.userInfo.manager_name === '' && this.props.userData.jwtToken !== '') {
      //login auth
      // setCookie("register_auto", "true", 24, "");
      // window.location= this.props.loginUrl;
      this.props.setValues({
        modal_open: 'message',
        modal_message: 'กรุณาเข้าสู่ระบบ'
      })
      return;
    }
    this.apiRegister();
  };
    
  handleRegisterShare = () => {
    if(this.props.inGame.status){
      this.props.setValues({
        modal_open: "ingame_registered",
      });
    } else {
      window.open(this.props.register.link_share, '_blank');
    }
  }
    
  handleRecieve = (index, data) => {
    if(this.props.inGame.status){
      this.props.setValues({
        modal_open: "ingame_shared",
      });
    } else {
      this.props.setValues({ 
        modal_open: 'share', 
        items_key: index, 
        items_score: data 
      });
    }
  }

  render() {
    const TotalView = this.numberWithCommas(this.props.milestones.user_registed);
    return (
      <SectionStyle id="section2">
        <ReactTooltip
          id='itemname'
          place='top'
          type='dark'
          effect='float'
          className='tooltip'
          html={true}
          overridePosition={({ left, top }, _e, _t, node) => {
            return {
                top, left: typeof node === 'string' ? left : Math.max(left, 0),
            };
          }}
        />
        <div className="background__video">
          <video loop muted autoPlay>
            <source src={VdoList.s2_bg_vdo} type="video/webm" />
          </video>
        </div>
        <div className="s2__content">
          <div className="register">
            <div className="register__title">
              <p>จำนวนผู้เข้าร่วม</p>
              <div>{ TotalView }<span> คน</span></div>
            </div>
            { this.props.userInfo.manager_name && !this.props.register.status  ?
              <div className="btn__register btn__register--shared" onClick={ () => this.handleRegisterShare() }>
                <div className="btn__register--img" />
              </div>
            :
              <div className="btn__register" onClick={ () => this.handleRegister() }>
                <div className="btn__register--img" />
              </div>
            }
          </div>
          <div className="reward">
            <div className="reward__content">
              {/* <div className="reward__title"><p>สิทธิพิเศษ!</p>&nbsp;สำหรับผู้สำรองสิทธิ์</div> */}
              <div className="reward__detail">
                <div>เฉพาะสมาชิก FOUNDERS CLUB เท่านั้น</div>
                <div>ลุ้นรับเสื้อ D. Beckham พร้อมลายเซ็น</div>
                <div>เพียงเข้าร่วมตอนนี้ - 9 กันยายน 2023</div>
              </div>
            </div>
            <div className="reward__banner">
              <img src={ ImgList.s2_banner } alt="banner" />
            </div>
          </div>
          <div className="reward__milestone">
            <div className="reward__milestone--title">
            เมื่อยอดกดรับสิทริ์ครบตามที่กำหนด ก็สามารถ "แชร์เพื่อรับของรางวัล"
            </div>
            <div className="milestone">
              <div className="milestone__gate">
                <div className="milestone__gate--bar">
                    <div className="milestone__gate--baractive" style={{ width: this.props.milestones.percent + "%" }}></div>
                </div>
                <div className="milestone__gate--point">
                  { this.props.milestones.items.map((item, index) => {
                    return (
                      <div key={ index }>
                        <div className={ item.status === 'disable' ? "" : "point__active" }></div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="milestone__reward">
                { this.props.milestones.items.map((item, index) => {
                  return (
                    <div key={ index } className={ item.status === 'enable' ? "active" : "disable" }>
                      <div className="milestone__img">
                        <img src={ item.image } alt={ "banner_" + index } data-for='itemname' data-tip={ item.name } data-iscapture="true" />
                      </div>
                      <div className="milestone__recieve" onClick={ () => this.handleRecieve(index, item.score)}>
                        <div className="milestone__title" style={{ background: 'url(' + (item.status === 'disable' ? '' : ImgList.s2_bg_recieve) + ') center center / contain no-repeat' }}>{ item.score }</div>
                        <div className="milestone__btn">{ item.status === 'clamed' ? "รับรางวัลแล้ว" : "แชร์เพื่อรับ" }</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </SectionStyle>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.Main, ...state.Modal , inGame: state.inGame });
const mapDispatchToProps = { setValues };
export default connect(mapStateToProps, mapDispatchToProps)(SectionTwo);

const SectionStyle = Styled.div`
  width: 100vw;
  height: calc(100vw / 1920 * 1080 );
  background: url(${ ImgList.s2_bg }) top center / cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    height: 100%;
  }
  .s2 {
    &__content {
      position: relative;
    }
  }
  .register {
    width: calc(100% / 1920 * 1080);
    margin: auto;
    &__title {
      max-width: 763px;
      width: 100%;
      margin: auto;
      >p {
        font-family: 'DBHelvethaicaX-64MedExtIt', Tahoma;
        font-size: 1.5em;
        color: #151616;
      }
      >div {
        width: 100%;
        font-family: 'DBHelvethaicaX-74BdExtIt', Tahoma;
        font-size: 4em;
        text-align: center;
        color: #07f468;
        background: url(${ ImgList.s2_bg_total_view }) center center / 100% 100% no-repeat;
        >span {
          font-size: 0.6em;
        }
      }
    }
    .btn {
      &__register {
        width: calc(100vw / 1920 * 333);
        margin: 1% auto 0;
        // background: url(${ ImgList.s2_btn_register_bg }) top center / cover no-repeat;
        cursor: pointer;
        &--img {
          width: 100%;
          height: calc(100vw / 1920 * 70);
          background-image: url(${ ImgList.s2_btn_register }); 
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
          transition: all .5s;
          &:hover {
            background-image: url(${ ImgList.s2_btn_register_hover });
            transition: all .5s;
          }
        }
        &--shared {
          .btn__register--img {
            background-image: url(${ ImgList.s2_btn_share }); 
            &:hover {
              background-image: url(${ ImgList.s2_btn_share_hover }); 
            }
          }
        }
      }
      &__registershared {
        width: calc(100vw / 1920 * 333);
        margin: 1% auto 0;
        // background: url(${ ImgList.s2_btn_register_bg }) top center / cover no-repeat;
        cursor: pointer;
        &--img {
          width: 100%;
          height: calc(100vw / 1920 * 70);
          background-image: url(${ ImgList.s2_btn_register }); 
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
          transition: all .5s;
          &:hover {
            background-image: url(${ ImgList.s2_btn_register_hover });
            transition: all .5s;
          }
        }
      }
    }
  }
  .reward {
    width: 80%;
    margin: 2% auto 0;
    display: flex;
    justify-content: center;
    &__content {
      width: calc(100% - 40%);
      text-align: center;
      align-self: center;
    }
    &__banner {
      width: 40%;
    }
    &__title {
      width: 65%;
      padding: 1% 1% 1% 0;
      font-family: 'DBHelvethaicaX-64MedExtIt', Tahoma;
      font-size: 1.5em;
      background: url(${ ImgList.s2_bg_title }) top center / 100% 100% no-repeat;
      @media only screen and (max-width: 900px) {
        width: 70%;
      }
        >p {
        font-family: 'DBHelvethaicaX-74BdExtIt', Tahoma;
        font-size: 1.1em;
        display: inline;
      }
    }
    &__detail {
      font-family: 'DBHelvethaicaX-63MedExt', Tahoma;
      >p {
        font-family: 'DBHelvethaicaX-73BdExt', Tahoma;
        font-size: 1.2em;
        display: inline;
      }
    }
    &__comment {
      font-size: 0.8em;
    }
    &__milestone {
      padding-top:2em;
      width: 70%;
      margin: auto;
      &--title {
        text-align: center;
      }
    }
  }
  .milestone {
    width: 100%;
    padding-top: calc(100% / 650 * 90);
    padding-bottom: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &__gate {
      width: 100%;
      margin: 0 auto;
      position: relative;
      background: #151616;
      border-radius: 6px;
      &--point {
        width: 100%;
        display: flex;
        justify-content: space-around;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        >div {
          width: 20px;
          height: 20px;
          background: #fff;
          border-radius: 50%;
          position: relative;
          .point__active {
            width: 10px;
            height: 10px;
            background: #161616;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      &--bar {
        border: 2px solid #fff;
        border-radius: 30px;
        overflow: hidden;
        &active {
          padding-top: calc(100% / 1920 * 18);
          background: url(${ ImgList.s2_milestone }) center center / cover no-repeat;
        }
      }
    }
    &__reward {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      >div {
        width: 16%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
    }
    &__recieve {
      margin-top: 20%;
      text-align: center;
      cursor: pointer;
    }
    .disable {
      filter:grayscale(0.8) brightness(0.8);
      .milestone__img {
        width: 65%;
        margin: auto;
      }
      .milestone__recieve {
        pointer-events: none;
        color: #868686;
      }
    }
    .active {
      .milestone {
        &__img {
          width: 80%;
          margin: auto;
        }
        &__recieve {
          color: #151616;
        }
        &__title {
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
        }
        &__btn {
          color: #151616;
          transition: all .5s;
          border-bottom: 1px solid transparent;
          &:hover {
            color: #07f468;
            padding: 0 0.5em;
            background:#151616;
            // border-bottom: 1px solid #151616;
            transition: all .5s;
          }
          @media only screen and (max-device-width: 1366px) {
            color: #07f468;
            border-bottom: 1px solid #151616;
          }
        }
      }
    }
  }
`;