import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setInGame } from './redux';

const SetIn = props => {
  let { cookie_name } = props.inGame;
  let newInGameData = {
    ...props.inGame,
    status: true
  };
  setCookie(cookie_name, 'true', 24);
  props.setInGame(newInGameData);

  return <Redirect to={props.to || '/'} />;
};

const mapStateToProps = state => ({ inGame: state.inGame });
const mapDispatchToProps = { setInGame };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetIn);

// function ====================================================================
const setCookie = (name, value, days) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};
//==============================================================================
