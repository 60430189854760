import React from "react";
import Styled from "styled-components";
import Slider from "react-slick";
import ImgList from "../constants/ImagesList";

const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 5000,
  cssEase: "linear"
};

const banner = [
  ImgList.s3_banner_1, 
  ImgList.s3_banner_2,
  // ImgList.s3_banner_3,
  // ImgList.s3_banner_4
];

class SectionThree extends React.Component{
  render() {
    return (
      <SectionStyle className="s3" id="section3">
        <div className="s3__title">
          <img src={ ImgList.s3_title } alt="title" />
        </div>
        <SlideStyle className="slide">
          <Slider { ...settings }>
            { banner.map((item,index) => {
              return (
                <img key={ index } className="slide__image" src={ item } alt={ item } />
              )})
            }
          </Slider>
        </SlideStyle>
      </SectionStyle>

    );
  }
}

export default SectionThree;

const SectionStyle = Styled.div`
  width: 100vw;
  height: auto;
  padding: 5% 0;
  background: url(${ImgList.s3_bg}) top left / cover no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .s3 {
    &__title {
      max-width: 922px;
      width: 50%;
      margin-bottom: 3%;
      display: block;
    }
  }
`;

const SlideStyle = Styled.div`
  width: 80%;
  .slick-dots li {
    button:before {
      width: 8px;
      height: 8px;
      content: '';
      background: #6b6969;
      border: 1px solid #151616;
      border-radius: 50%;
      opacity: 1;
    }
    &.slick-active button:before {
      background: #07f468;
    }
  }
`;